<div class="search search-container d-flex justify-content-center align-items-center flex-row h-100 cursor-pointer">
  <div class=" search-wrapper wrapper w-100">
    <button class="border-0 bg-transparent ms-2 me-0">
      <img [src]="'/assets/images/ic_search.svg'">
    </button>
    <input
      (keydown)="onEnterButtonClick()"
      [(ngModel)]="search"
      placeholder="{{'accountsHeader.search' | translate}}"
      class="search-input w-70 input bg-transparent border-2 p-3 cursor-pointer"
      type="text"
    >
  </div>
</div>
