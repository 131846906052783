<div style="background-color: #281547; z-index: 999;" class="search search-container d-flex justify-content-center align-items-center flex-row position-relative h-100 cursor-pointer">
  <div class="position-absolute w-100"
    [@slideUpDown]="editableMessage ? 'opened' : 'closed'"
    style="
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    ">
    <div style="border-left: 2px solid grey; background: #271546;" class="d-flex justify-content-between ps-3">
      <div class="d-flex flex-column align-items-start">
        <strong>Editable text</strong>
        <span style="font-style: italic;">{{editableMessage?.message}}</span>
      </div>
      <button (click)="onCloseEditBar(); search = ''" class="border-0 bg-transparent">
        <img style="width: 15px; height: 15px;" src="../../../../assets/images/ic_close.svg">
      </button>
    </div>
  </div>
  <div style="background-color: #271546;" class=" search-wrapper wrapper w-100 position-relative d-inline-flex flex-row justify-content-center align-items-center">
    <button class="border-0 bg-transparent">
       <i class=" pi pi-upload"></i>
    </button>
    <textarea
      #inputElement
      style="height: 51px"
      [class.opacity-03]="isLoading"
      [disabled]="isLoading"
      (keydown.enter)="onSendButtonClick()"
      [(ngModel)]="search"
      placeholder="{{'feedback.chat.input.placeholder' | translate}}"
      class="search-input w-95 input bg-transparent border-2 p-3 cursor-pointer"
      type="text"
    ></textarea>
    <div *ngIf="isLoading" style="height: 100%; width : 5%; right: 10px; top: 6px;" class="position-absolute">
      <img width="40" src="../../../../../assets/gifs/gif_public.gif">
    </div>
    <button *ngIf="!isLoading" (click)="onSendButtonClick()" class="border-0 bg-transparent" style="width: 5%">
      <img *ngIf="!editableMessage" src='./assets/images/ic_send.svg' class="position-absolute cursor-pointer" style="right: 7px; top: 13px;">
      <i *ngIf="editableMessage" class="pi pi-check position-absolute cursor-pointer" style="right: 8px; top: 17px; font-size: 1.2rem"></i>
    </button>
  </div>
</div>
