<router-outlet *ngIf="!siteLoader; else loading"></router-outlet>
<ng-template #loading>
  <div class="site-loading">
  </div>
</ng-template>

<p-toast *ngIf="isSmallSizeToaster; else bigSizeToaster" position="bottom-center" [showTransitionOptions]="'1000ms'"></p-toast>
<ng-template #bigSizeToaster>
  <p-toast position="bottom-right" [showTransitionOptions]="'1000ms'"></p-toast>

</ng-template>
